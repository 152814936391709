<template>
    <div class="storelanding_container">
        <div class="storelanding_logo_wrap">
            <img class="storelanding_logo" src="/media/img/landing/store/storelanding_logo.png" alt="어플레이즈로고" />
        </div>
        <section class="storelanding_section1">
            <h2 class="storelanding_section1_title1">사장님, 점주님들의 현명한 선택</h2>
            <h1 class="storelanding_section1_title2">
                AI 매장 음악 플레이어, <br />
                어플레이즈
            </h1>
            <h3 class="storelanding_section1_subtitle">
                테라로사, 애슐리퀸즈, 자연별곡, 역전할머니맥주는 이미 어플레이즈 이용 중!
            </h3>

            <div class="flex_justify_center">
                <button @click="goLogin" class="storelanding_btn">지금 무료 체험하기</button>
            </div>
        </section>
        <section class="storelanding_section2">
            <h2 class="storelanding_section2_title">
                음악이 필요한 공간이라면 어디든!
            </h2>
            <ul class="storelanding_section2_imglist">
                <li class="storelanding_section2_item">
                    <ul class="dp_flex">
                        <li class="storelanding_section2_subitem">
                            <img
                                class="storelanding_section2_img"
                                src="/media/img/landing/store/storesection2_img1.png"
                                alt="카페,음식점"
                            />
                            <span class="storelanding_section2_text">카페, 음식점</span>
                        </li>
                        <li class="storelanding_section2_subitem last">
                            <img
                                class="storelanding_section2_img"
                                src="/media/img/landing/store/storesection2_img2.png"
                                alt="헬스장"
                            />
                            <span class="storelanding_section2_text">헬스장</span>
                        </li>
                    </ul>
                </li>
                <li class="storelanding_section2_item last">
                    <ul class="dp_flex">
                        <li class="storelanding_section2_subitem">
                            <img
                                class="storelanding_section2_img"
                                src="/media/img/landing/store/storesection2_img3.png"
                                alt="갤러리"
                            />
                            <span class="storelanding_section2_text">갤러리</span>
                        </li>
                        <li class="storelanding_section2_subitem last">
                            <img
                                class="storelanding_section2_img"
                                src="/media/img/landing/store/storesection2_img4.png"
                                alt="편집샵"
                            />
                            <span class="storelanding_section2_text">편집샵</span>
                        </li>
                    </ul>
                </li>
            </ul>
        </section>
        <section class="storelanding_section3">
            <div class="storelanding_section3_title_wrap">
                <img
                    class="storelanding_section3_title_quotes1"
                    src="/media/img/landing/store/doublequotes1.png"
                    alt="doublequotes1.png"
                />
                <h1 class="storelanding_section3_title">재결제율 98%</h1>
                <img
                    class="storelanding_section3_title_quotes2"
                    src="/media/img/landing/store/doublequotes2.png"
                    alt="doublequotes1.png"
                />
            </div>

            <div class="storelanding_section3_subtext1">
                우리 매장에 딱 맞는 음악으로 알아서 선곡해줘요.
            </div>
            <div class="storelanding_section3_subtext2">
                어플레이즈 모르던 시절, 선곡하는데 쓴 시간이 너무 아깝습니다.
            </div>

            <div class="storelanding_section3_subtext3">
                이젠 저작권 걱정없이 음악 틀어요. 광고 끊김 걱정도 없어서 너무 좋습니다.
            </div>

            <div class="storelanding_section3_subtext4">
                단골 손님이 많아진 이유 중 하나! 어플레이즈 음악 선곡!
            </div>
        </section>
        <section class="storelanding_section4">
            <h1 class="storelanding_section4_title">어플레이즈 미리보기</h1>

            <figure class="storelanding_section4_img1_wrap">
                <img
                    class="storelanding_section4_img"
                    src="/media/img/landing/store/section4_img1.png"
                    alt="어플레이즈 미리보기 이미지"
                />
            </figure>

            <p class="storelanding_section4_paragraph">
                APLAYZ는 AI 기술로 매장 분위기에 맞는 음악을 실시간으로 <br />
                추천 & 재생하여, 매장을 방문한 고객에게 최고의 경험을 선사합니다.
            </p>

            <h2 class="storelanding_section4_title2">
                나갈 돈도 많은데, 매장 음악에 까지 돈을 써야할까요?
            </h2>
            <p class="storelanding_section4_paragraph2">
                매장 음악은 <strong class="storelanding_section4_paragraph2_strong">브랜드의 이미지</strong>를 대표합니다.
                <br />
                실제 고객님들의 의견을 들어볼까요?
            </p>

            <div class="storelanding_section4_dotwrap">
                <span class="white_dot"></span>
                <span class="white_dot second"></span>
                <span class="white_dot third"></span>
            </div>
            <div class="storelanding_section4_paragraph_wrap">
                <p class="storelanding_section4_paragraph3">
                    “음악 안 나오는 가게는 들어가기가 꺼려지죠.. 뭘 먹어도 불편할 듯”
                </p>
                <p class="storelanding_section4_paragraph4">“분위기에 안 맞는 음악이 나오면, 다음에는 안가게 되더라고요.”</p>
                <p class="storelanding_section4_paragraph5">“사장님 선곡 센스 좋은 곳은 무조건 재방문하죠!”</p>
            </div>
        </section>
        <section class="storelanding_section5">
            <p class="storelanding_section5_paragraph">
                매장 음악 브랜딩,<br />
                어플레이즈가 다 해드립니다. <br />
                이제 재생 버튼만 누르세요.
            </p>
            <div class="flex_justify_center">
                <button class="storelanding_btn section5_btn" @click="goLogin">지금 가입하고 무료로 체험하기</button>
            </div>
            <figure class="storelanding_section5_logo">
                <img class="storelanding_logo_img" src="/media/img/landing/store/section5_logo.png" alt="section5_logo.png" />
            </figure>
        </section>
    </div>
</template>

<script>
export default {
  name: 'StoreLanding',

  data () {
    return {};
  },

  mounted () {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#ffffff';
    body.style.height = 'auto';
  },

  destroyed () {
    const body = document.querySelector('body');
    body.style.backgroundColor = '';
    body.style.height = '';
  },

  methods: {
    goLogin () {
      this.$router.push({ name: 'Login' }).catch(() => {});
    }
  }
};
</script>
<style src="@/assets/css/landing/landingfont.css"></style>
<style scoped src="@/assets/css/landing/storelanding.css"></style>
